import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		users: [],
	},
	reducers: {
		setUser(state, action) {
			let userIndex = state.users.findIndex((user) => user.id === action.payload.id);
			if (userIndex >= 0) {
				state.users[userIndex] = action.payload;
			} else {
				state.users.push(action.payload);
			}
		},
	},
});

export const userActions = userSlice.actions;
export default userSlice.reducer;
